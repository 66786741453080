import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Loader from './components/Loader';
import reportWebVitals from './reportWebVitals';
// import TagManager from 'react-gtm-module';
// import css  https://github.com/react-grid-layout/react-grid-layout#installation
import '../node_modules/react-resizable/css/styles.css';
import '../node_modules/react-grid-layout/css/styles.css';

const App = React.lazy(() => import(/* webpackChunkName: "app" */ './App'));

// const tagManagerArgs = {
//   gtmId: 'GTM-NM9HRHC',
//   dataLayer: {
//     userId: '001',
//     userProject: 'verloop-platform',
//   },
// };
//
// TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
