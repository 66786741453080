// libs
import { memo } from 'react';

// styles
import {
  Container,
  InnerContainer,
  CoffeeCup,
  teaBagClassName,
  steamLeftClassName,
  steamRightClassName,
} from './styles';

interface LoaderProps {
  className?: string;
}

function Loader({ className = '' }: LoaderProps) {
  return (
    <Container className={className}>
      <InnerContainer>
        <CoffeeCup
          teaBagClassName={teaBagClassName}
          steamLeftClassName={steamLeftClassName}
          steamRightClassName={steamRightClassName}
        />
      </InnerContainer>
    </Container>
  );
}

export default memo(Loader);
