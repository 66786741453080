// libs
import styled from '@styled';
import { keyframes } from '@emotion/react';

// components
import CoffeeCupBase from 'src/svg/CoffeeCup';

const swing = keyframes`
  50% {
    transform: rotate(-3deg);
  }
`;

const steamLarge = keyframes`
  0% {
    stroke-dashoffset: 13;
    opacity: .6;
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
`;

const steamSmall = keyframes`
  10% {
    stroke-dashoffset: 9;
    opacity: .6;
  }
  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: ${({ theme }) =>
    theme?.background?.color?.main || '#f0f3fa'};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const InnerContainer = styled.div`
  display: flex;
  height: 104px;
  width: 104px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme?.background?.color?.empty || '#fff'};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.24), 0 3px 8px 0 rgba(0, 0, 0, 0.08);
  align-items: center;
  justify-content: center;
`;

export const teaBagClassName = 'loader-tea-bag';
export const steamLeftClassName = 'loader-steam-left';
export const steamRightClassName = 'loader-steam-right';

export const CoffeeCup = styled(CoffeeCupBase)`
  & .${teaBagClassName} {
    transform-origin: top center;
    transform: rotate(3deg);
    animation: ${swing} 2s infinite;
  }

  & .${steamLeftClassName} {
    stroke-dasharray: 13;
    stroke-dashoffset: 13;
    animation: ${steamLarge} 2s infinite;
  }

  & .${steamRightClassName} {
    stroke-dasharray: 9;
    stroke-dashoffset: 9;
    animation: ${steamSmall} 2s infinite;
  }
`;
