// libs
import styled from '@emotion/styled';

export default styled;

// ** old code commented below ** CreateStyled is depricated in new emotion version 11
// check emotion.d.ts for typescript support
/**
 * // libs
import styled, { CreateStyled } from '@emotion/styled';

// constants
import { THEME as THEME_INFO } from 'src/styles/tokens';

type Theme = typeof THEME_INFO;

export default styled as CreateStyled<Theme>;
 * 
 * 
 */
